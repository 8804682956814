import React from "react"
import Layout from "../components/layout"
import "flag-icon-css/css/flag-icon.css"
import SEO from "../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Masonry from "react-masonry-component"

const About = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "AboutImage.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="About" />
      <Masonry>
        <div className="w-full sm:w-full md:w-full lg:w-6/12 xlg:w-6/12 p-4">
          <div className="rounded overflow-hidden shadow-2xl bg-gray-200">
            <Img fluid={data.placeholderImage.childImageSharp.fluid} />
          </div>
        </div>
        <div className="w-full sm:w-full md:w-full lg:w-6/12 xlg:w-6/12 p-4">
          <div className="rounded border-r border-b border-l border-gray-400 lg:border-l-0 lg:border-t lg:border-gray-400 bg-white p-4 flex flex-col justify-between leading-normal">
            <div className="text-gray-900 font-bold text-xl mb-2">
              John Katsaras
            </div>
            <p className="text-gray-700 text-base whitespace-pre-wrap">
              My bike collecting days started in the early 2000s, slowly at
              first because of limited funds and the low valuation of the
              Canadian dollar vs the US dollar - at one point ( January 21st,
              2002), $0.6179 US bought $1 Canadian. However, my collection has
              grown substantially, since moving to the US in 2010.
              <br />
              <br />
              At the beginning, I concentrated on bikes from US builders, but as
              the years went by, my interests changed to bikes from European
              makes and builders from the 50s, 60s and 70s. This website shows
              all of the bikes that I have brought to specification, but most of
              my collection is still in boxes. Hopefully, they will also join
              their brethren on this website in the not too distant future.
              <br />
              <br />
              Enjoy, John Katsaras
            </p>
          </div>
        </div>
      </Masonry>
    </Layout>
  )
}

export default About
